import Vue from "vue"
import DatePicker from 'vue2-datepicker'
import SortedTablePlugin from "vue-sorted-table"
import Paginate from "@/components/widgets/pagination.vue";

Vue.use(SortedTablePlugin, {
  ascIcon: '<i class="mdi mdi-arrow-down"></i>',
  descIcon: '<i class="mdi mdi-arrow-up"></i>'
})
export default {
  components: {
    DatePicker,
    Paginate
  },
  data: function() {
    return {
      busy: false, 
      advanced: false,
      status: null,
      doc: {
        start: null,
        end: null
      },
      perPage: 6,
      sortBy: 'name',
      currentPage: 1,
      currentData: {},
      sortDesc: false,
      totalItem: 0,
      apiStatus: 'ไม่พบข้อมูล',
      fields: [
        { 
          key: 'purchasedOn', 
          label: 'วันที่เอกสาร',
          thClass: 'font-weight-bold text-xs-1/2',
          tdClass: 'td-top text-xs-1/2',
          sortable: true 
        },
        { 
          key: 'doc', 
          label: 'เลขที่เอกสาร',
          thClass: 'font-weight-bold text-xs-1/2',
          tdClass: 'text-gray-400 td-top text-xs-1/2',
          sortable: true 
        },
        { 
          key: 'inv_doc', 
          label: 'เลขที่ใบกำกับภาษี',
          thClass: 'font-weight-bold text-xs-1/2',
          tdClass: 'text-gray-400 td-top text-xs-1/2',
          sortable: true 
        },
        { 
          key: 'bill_doc', 
          label: 'เลขที่ใบวางบิล',
          thClass: 'font-weight-bold text-xs-1/2',
          tdClass: 'text-gray-400 td-top text-xs-1/2',
          sortable: true 
        },
        { 
          key: 'debtorCode', 
          label: 'รหัสลูกหนี้',
          thClass: 'font-weight-bold text-xs-1/2',
          tdClass: 'text-gray-400 td-top text-xs-1/2',
          sortable: true 
        },
        { 
          key: 'debtorName', 
          label: 'ชื่อลูกหนี้',
          thClass: 'font-weight-bold text-xs-1/2',
          tdClass: 'text-gray-400 td-top text-xs-1/2', 
          sortable: true 
        },
        { 
          key: 'nettotal', 
          label: 'ยอดรวมสุทธิ',
          thClass: 'font-weight-bold text-xs-1/2',
          tdClass: 'text-gray-400 td-top text-xs-1/2',
          sortable: true, 
          class: 'text-right' 
        },
        { 
          key: 'debt', 
          label: 'ยอดค้างชำระ',
          thClass: 'font-weight-bold text-xs-1/2',
          tdClass: 'text-gray-400 td-top text-xs-1/2',
          sortable: true, 
          class: 'text-right' 
        },
        { 
          key: 'paid', 
          label: 'ยอดชำระ',
          thClass: 'font-weight-bold text-xs-1/2',
          tdClass: 'text-gray-400 td-top text-xs-1/2',
          sortable: true, 
          class: 'text-right' 
        },
        { 
          key: 'action', 
          label: '#', 
          thClass: 'font-weight-bold text-xs-1/2',
          tdClass: 'text-gray-400 td-top text-xs-1/2',
          class: 'text-right' 
        }
      ],
      items: [
        {
          _id: '123456',
          doc: 'BN-20221205465',
          inv_doc: 'INV-202216456',
          bill_doc: 'AR-202216456',
          purchasedOn: new Date(),
          debtorCode: 'DC-0001',
          debtorName: 'กชมน',
          responsible: 'มนชิรา',
          nettotal: 5000,
          debt: 5000,
          paid: 5000,
          dueDate: new Date()
        }
      ]
    }
  },
  computed: {
    rows() {
      return this.items.length
    }
  },
  methods: {
    onInitData() {
      try {
        const item = this.items
        this.items = []
        this.busy = true
        this.apiStatus = 'กำลังค้นหาข้อมูล..'
        
        setTimeout(() => {
          this.busy = false
          this.apiStatus = 'ไม่พบข้อมูล'
          
          this.items = item
        }, 1000)
      } catch (error) {
        this.busy = false
        this.apiStatus = 'ไม่พบข้อมูล'
        this.onExceptionHandler(error.response.data.message);
      }
    },
    onInfo(id) { }
  }
}
